<template>

	<div class="uk-visible@m" style="width: 220px; position: fixed; top: 46px; bottom: 0; left: 250px; box-sizing: border-box; border-right: 1px #e5e5e5 solid; overflow: auto; background-color: white;">
		<ul class="uk-nav uk-nav-default documentacion" style="padding: 20px">
			<li><router-link to="/administracion/documentacion">Introducción</router-link></li>
			<li class="uk-nav-header" style="margin-top: 30px">Recursos REST</li>
			<li class="uk-parent">
				<router-link to="/administracion/documentacion/recursos/eventos">/eventos</router-link>
				<ul class="uk-nav-sub">
					<li><router-link to="/administracion/documentacion/recursos/eventos/verificar-codigo">/verificar-codigo</router-link></li>
				</ul>
			</li>
			<li><router-link to="/administracion/documentacion/recursos/entes">/entes</router-link></li>
			<li class="uk-nav-header" style="margin-top: 30px">Tipos</li>
			<li><router-link to="/administracion/documentacion/tipos/evento">Evento</router-link></li>
			<li><router-link to="/administracion/documentacion/tipos/variables">Variables</router-link></li>
			<li><router-link to="/administracion/documentacion/tipos/origen">Origen</router-link></li>
			<li><router-link to="/administracion/documentacion/tipos/dispositivo">Dispositivo</router-link></li>
			<li><router-link to="/administracion/documentacion/tipos/adquisicion">Adquisicion</router-link></li>
			<li><router-link to="/administracion/documentacion/tipos/pago">Pago</router-link></li>
			<li><router-link to="/administracion/documentacion/tipos/receptor-de-facturacion">ReceptorDeFacturacion</router-link></li>
			<li><router-link to="/administracion/documentacion/tipos/destinatario-de-envio">DestinatarioDeEnvio</router-link></li>
			<li><router-link to="/administracion/documentacion/tipos/direccion-postal">DireccionPostal</router-link></li>
			<li><router-link to="/administracion/documentacion/tipos/resultado-de-evento">ResultadoDeEvento</router-link></li>
		</ul>
	</div>

  <div class="contenedor uk-padding" style="margin-left: 220px">
		<router-view></router-view>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Documentacion'
});
</script>